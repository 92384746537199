import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import '../styles/DynamicSliderSection.css';

function DynamicSliderSection({ id, title, images, descriptions, reverse = false }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [hover, setHover] = useState(false);
  const total = images.length;
  
  const getInterval = () => 2000 + descriptions[currentIndex].length * 50;
  
  useEffect(() => {
    if (!paused) {
      const timer = setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % total);
      }, getInterval());
      return () => clearTimeout(timer);
    }
  }, [currentIndex, paused, total, descriptions]);
  
  const prevSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev - 1 + total) % total);
  };
  
  const nextSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev + 1) % total);
  };
  
  const togglePause = (e) => {
    e.stopPropagation();
    setPaused(!paused);
  };
  
  const renderDescription = (text) => {
    if (text.includes("\n")) {
      return (
        <ul>
          {text.split("\n").map((line, i) => (
            <li key={i}>{line.replace(/^- ?/, "")}</li>
          ))}
        </ul>
      );
    } else {
      return <p>{text}</p>;
    }
  };
  
  const currentDescription = descriptions[currentIndex] || "";
  const parts = currentDescription.split(":");
  const subtitle = parts[0] ? parts[0].trim() : "";
  const detail = parts[1] ? parts.slice(1).join(":").trim() : "";
  
  return (
    <section id={id} className="section">
      <SectionTitle reverse={reverse}>{title}</SectionTitle>
      <div className={`dynamic-slider-section ${reverse ? "reverse" : ""}`}>
        {/* Background image element replacing the CSS ::before */}
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: "url('/assets/icons/logo_bg.png')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '20%',
            backgroundAttachment: 'fixed',
            opacity: 0.1,
            zIndex: -1
          }}
        />
        
        <div className="slider-container">
          <div 
            className="smartphone-frame" 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            style={{
              backgroundImage: "url('/images/logo_bg.png')",
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          >
            <div className="slider">
              <img 
                src={images[currentIndex]} 
                alt="slide" 
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "cover", 
                  borderRadius: "31px" 
                }} 
              />
              {hover && (
                <>
                  <button className="nav left" onClick={prevSlide}>&#9664;</button>
                  <button className="nav right" onClick={nextSlide}>&#9654;</button>
                  <div className="play-button" onClick={togglePause}>
                    {paused ? "►" : "❚❚"}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div key={currentIndex} className="description">
          <h3>{subtitle}</h3>
          {renderDescription(detail)}
        </div>
      </div>
    </section>
  );
}

export default DynamicSliderSection;