import React from 'react';
import SectionTitle from './SectionTitle';
import '../styles/TeamSection.css';

function TeamSection() {
  return (
    <section id="team" className="section team-section">
      <SectionTitle>Our Team</SectionTitle>
      <div className="team-container">
        <div className="team-member">
          <img src="/assets/icons/logo_small.png" alt="Jerry" />
          <h3>Jerry</h3>
          <p>
            <a href="https://t.me/JerrySeinfeId" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-telegram" style={{ fontSize: "28px", verticalAlign: "middle", color: "#fff" }}></i>
            </a>
          </p>
          <p>Founder & Full-Stack Dev</p>
        </div>
        <div className="team-member">
          <img src="/assets/icons/logo_small.png" alt="Fred" />
          <h3>Fred</h3>
          <p>
            <a href="https://t.me/PremiumJeet" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-telegram" style={{ fontSize: "28px", verticalAlign: "middle", color: "#fff" }}></i>
            </a>
          </p>
          <p>Founder & Full-Stack Dev</p>
        </div>
        <div className="team-member">
          <img src="/assets/icons/logo_small.png" alt="Paul" />
          <h3>Paul</h3>
          <p>
            <a href="https://t.me/ProficyCMO" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-telegram" style={{ fontSize: "28px", verticalAlign: "middle", color: "#fff" }}></i>
            </a>
          </p>
          <p>Marketing & PR</p>
        </div>
      </div>
    </section>
  );
}

export default TeamSection;