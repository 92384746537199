import React from 'react';
import '../styles/PriceLookup.css';

function PriceLookup() {
  // This is the disabled version that only shows a message
  return (
    <div className="coming-soon-analyzer">
      <h3>Web Token Analyzer Coming Soon</h3>
      <p>Our powerful token analyzer will be available here shortly.</p>
      <p>Try our Telegram bot in the meantime!</p>
      <a 
        href="https://t.me/ProficyPriceBot" 
        target="_blank" 
        rel="noopener noreferrer"
        className="try-bot-button"
      >
        Try @ProficyPriceBot <i className="fa-brands fa-telegram"></i>
      </a>
    </div>
  );
}

export default PriceLookup;