import React from 'react';
import PriceLookup from './PriceLookup';
import '../styles/HomeSection.css';

function HomeSection({ openDocModal }) {
  const stats = {
    groups: "92,000+",
    userReach: "10.4 Million+",
    tokensSearched: "2 Million+",
    totalCommands: "42 Million+",
  };
  
  return (
    <section id="home" className="section home-section">
      <div className="social-links">
        <a href="https://x.com/ProficyIO" target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-x-twitter"></i>
        </a>
        <a href="https://t.me/ProficyPriceBot" target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-telegram"></i>
        </a>
        <a href="#" onClick={(e) => { e.preventDefault(); openDocModal(); }}>
          <svg 
            className="gitbook-icon"
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            width="1em" 
            height="1em" 
            fill="currentColor"
            style={{ verticalAlign: "middle", marginBottom: "5px" }}
          >
            <path d="M12.513 1.097c-.645 0-1.233.34-2.407 1.017L3.675 5.82A7.233 7.233 0 0 0 0 12.063v.236a7.233 7.233 0 0 0 3.667 6.238L7.69 20.86c2.354 1.36 3.531 2.042 4.824 2.042 1.292.001 2.47-.678 4.825-2.038l4.251-2.453c1.177-.68 1.764-1.02 2.087-1.579.323-.56.324-1.24.323-2.6v-2.63a1.04 1.04 0 0 0-1.558-.903l-8.728 5.024c-.587.337-.88.507-1.201.507-.323 0-.616-.168-1.204-.506l-5.904-3.393c-.297-.171-.446-.256-.565-.271a.603.603 0 0 0-.634.368c-.045.111-.045.282-.043.625.002.252 0 .378.025.494.053.259.189.493.387.667.089.077.198.14.416.266l6.315 3.65c.589.34.884.51 1.207.51.324 0 .617-.17 1.206-.509l7.74-4.469c.202-.116.302-.172.377-.13.075.044.075.16.075.392v1.193c0 .34.001.51-.08.649-.08.14-.227.224-.522.394l-6.382 3.685c-1.178.68-1.767 1.02-2.413 1.02-.646 0-1.236-.34-2.412-1.022l-5.97-3.452-.043-.025a4.106 4.106 0 0 1-2.031-3.52V11.7c0-.801.427-1.541 1.12-1.944a1.979 1.979 0 0 1 1.982-.001l4.946 2.858c1.174.679 1.762 1.019 2.407 1.02.645 0 1.233-.34 2.41-1.017l7.482-4.306a1.091 1.091 0 0 0 0-1.891Z" />
          </svg>
        </a>
      </div>
      
      <h1 className="glow-title" style={{ marginTop: "10px" }}>
        Proficy
      </h1>
      
      <p>Your all-in-one solution for Token Security & DeFi Market Analysis</p>
      
      <PriceLookup />
      
      <div className="stats-container">
        <div className="stat-item">
          <h3>{stats.groups}</h3>
          <p>Active Groups</p>
        </div>
        <div className="stat-item">
          <h3>{stats.userReach}</h3>
          <p>User Reach</p>
        </div>
        <div className="stat-item">
          <h3>{stats.tokensSearched}</h3>
          <p>Tokens Tracked</p>
        </div>
        <div className="stat-item">
          <h3>{stats.totalCommands}</h3>
          <p>Commands Processed</p>
        </div>
      </div>
    </section>
  );
}

export default HomeSection;