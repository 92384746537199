import React, { useRef, useEffect } from 'react';
import '../styles/SectionTitle.css';

function SectionTitle({ children, reverse = false }) {
  const ref = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            ref.current.classList.add("visible");
            observer.unobserve(ref.current);
          }
        });
      },
      { threshold: 0.5 }
    );
    
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  
  return (
    <div className={`section-title ${reverse ? "reverse" : ""}`} ref={ref}>
      <h2>{children}</h2>
    </div>
  );
}

export default SectionTitle;