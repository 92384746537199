import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';

function Navbar({ activeSection }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
      if (window.innerWidth >= 600) setMenuOpen(false);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".hamburger-menu")) setMenuOpen(false);
    };
    
    const handleScroll = () => setMenuOpen(false);
    
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) section.scrollIntoView({ behavior: "smooth" });
    setMenuOpen(false);
  };
  
  const sections = [
    { id: "home", label: "Home" },
    { id: "tokenSecurity", label: "Token Security" },
    { id: "dexAnalysis", label: "DEX Analysis" },
    { id: "utilities", label: "Utilities" },
    { id: "advertisement", label: "Advertisement" },
    { id: "team", label: "Team" },
  ];
  
  return (
    <nav className="navbar">
      <div className="logo" onClick={() => scrollToSection('home')}>
        <img src="/assets/icons/logo_small.png" alt="Logo" className="clickable-logo" />
      </div>
      
      {isMobile ? (
        <div className="hamburger-menu">
          <button onClick={(e) => { e.stopPropagation(); setMenuOpen(!menuOpen); }}>
            {menuOpen ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <i className="fa-solid fa-bars"></i>
            )}
          </button>
          
          {menuOpen && (
            <div className="mobile-nav">
              {sections.map((section) => (
                <button 
                  key={section.id} 
                  onClick={() => scrollToSection(section.id)}
                >
                  {section.label}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="nav-buttons">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => scrollToSection(section.id)}
              className={activeSection === section.id ? "active" : ""}
            >
              {section.label}
            </button>
          ))}
        </div>
      )}
    </nav>
  );
}

export default Navbar;