import React from 'react';
import SectionTitle from './SectionTitle';
import '../styles/PartnersSection.css';

function PartnersSection() {
  const partnerLogos = [
    { src: "/assets/partners_cropped/dexscreener.png", url: "https://dexscreener.com" },
    { src: "/assets/partners_cropped/maestro.png", url: "https://maestrobots.com" },
    { src: "/assets/partners_cropped/photon.png", url: "https://photon-sol.tinyastro.io" },
    { src: "/assets/partners_cropped/sonic.png", url: "https://sonicsnipebot.xyz" },
    { src: "/assets/partners_cropped/solscan.png", url: "https://solscan.io" },
    { src: "/assets/partners_cropped/moonarch.png", url: "https://moonarch.app" },
    { src: "/assets/partners_cropped/dexview.png", url: "https://dexview.com" },
    { src: "/assets/partners_cropped/dextools.png", url: "https://www.dextools.io" },
    { src: "/assets/partners_cropped/hpis.png", url: "https://honeypot.is" },
    { src: "/assets/partners_cropped/bubblemaps.png", url: "https://bubblemaps.io" }
  ];
  
  // Duplicate to create an endless loop effect
  const partners = [...partnerLogos, ...partnerLogos];

  const chainLogos = [
    { src: "/assets/chains/eth.png", url: "https://ethereum.org" },
    { src: "/assets/chains/bsc.png", url: "https://www.binance.org/en" },
    { src: "/assets/chains/sol.png", url: "https://solana.com" },
    { src: "/assets/chains/arb.png", url: "https://arbitrum.io" },
    { src: "/assets/chains/sui.png", url: "https://sui.io" },
    { src: "/assets/chains/tron.png", url: "https://tron.network" },
    { src: "/assets/chains/avax.png", url: "https://www.avax.network" },
    { src: "/assets/chains/ton.png", url: "https://ton.org" },
    { src: "/assets/chains/cro.png", url: "https://crypto.com" },
    { src: "/assets/chains/uni.png", url: "https://unichain.org" },
    { src: "/assets/chains/xrp.png", url: "https://ripple.com/xrp" }
  ];
  
  // Duplicate chains for continuous marquee
  const chains = [...chainLogos, ...chainLogos];

  return (
    <section id="partners" className="section">
      <SectionTitle>Partners & Chains</SectionTitle>
      <div className="partners-carousel" style={{ marginBottom: "40px" }}>
        <div className="marquee">
          {partners.map((item, idx) => (
            <a key={idx} href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.src} alt={`Partner ${idx + 1}`} />
            </a>
          ))}
        </div>
      </div>
      <div className="partners-carousel">
        <div className="marquee2 reverse">
          {chains.map((item, idx) => (
            <a key={idx} href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.src} alt={`Chain ${idx + 1}`} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PartnersSection;