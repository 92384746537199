import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import HomeSection from './components/HomeSection';
import DynamicSliderSection from './components/DynamicSliderSection';
import PartnersSection from './components/PartnersSection';
import TeamSection from './components/TeamSection';
import DocModal from './components/DocModal';
import TOSModal from './components/TOSModal';
import './styles/App.css';

function App() {
  const [activeSection, setActiveSection] = useState("home");
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);
  
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const observer = new IntersectionObserver(
      (entries) => {
        const visible = Array.from(entries).filter(
          (entry) => entry.isIntersecting && entry.intersectionRatio > 0.2
        );
        if (visible.length > 0) {
          const mostVisible = visible.reduce((max, entry) =>
            entry.intersectionRatio > max.intersectionRatio ? entry : max
          );
          setActiveSection(mostVisible.target.id);
        }
      },
      { threshold: [0, 0.2, 0.4, 0.6, 0.8, 1], rootMargin: "-50px 0px -50px 0px" }
    );
    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);

  return (
    <div className="App">
      <Navbar activeSection={activeSection} />
      <HomeSection openDocModal={() => setShowDocModal(true)} />
      <DynamicSliderSection
        id="tokenSecurity"
        title="Token & Trading Security"
        images={[
          "/assets/screenshots/p_msg.jpg",
          "/assets/screenshots/c_b_msg.jpg",
          "/assets/screenshots/ce_msg.jpg",
        ]}
        descriptions={[
          "Token Analysis: Real-time analysis of token price & volume dynamics\nComprehensive security check and overview\nSwap tokens directly with our partners",
          "Token Charts & Bubblemaps: Fastest charts in Telegram - Switch quickly through all available time intervals\nIn depth Bubblemaps to spot bundles and other scams",
          "Contract Checksum: Deep contract code check and comparison with other existing tokens\nProficy holds the largest Checksum Database with more than 2 million analyzed tokens",
        ]}
        reverse={false}
      />
      <DynamicSliderSection
        id="dexAnalysis"
        title="DEX Analysis"
        images={[
          "/assets/screenshots/trending.jpg",
          "/assets/screenshots/index.jpg",
          "/assets/screenshots/volume_main.jpg",
          "/assets/screenshots/volume.jpg",
          "/assets/screenshots/pairs.jpg",
        ]}
        descriptions={[
          "Trending Tokens: Real-time tracking of the top trending tokens on the Proficy Price Bot\nGet an insight into which tokens millions of people are searching for in TG groups",
          "Market Overview: Aggregated indices providing a consolidated snapshot of market trends\nGet a quick overview over the major market movements",
          "Volume Analysis: Extended view of trading volumes and liquidity trends on various chains\nGet a feeling for where the action is happening right now",
          "Volume Analysis: Extended view of trading volumes and liquidity trends on various chains\nGet a feeling for where the action is happening right now",
          "New Pairs: Real-time tracking of new token pair creations on various chains\nBe one of the first to invest into freshly launched projects",
        ]}
        reverse={true}
      />
      <DynamicSliderSection
        id="utilities"
        title="Utilities"
        images={[
          "/assets/screenshots/qa.jpg",
          "/assets/screenshots/flexstats.jpg",
        ]}
        descriptions={[
          "Chat GPT: Ask the latest AI model questions directly or refer messages to it\nThe power of Chat GPT at your fingertips in a Telegram chat",
          "Flex Stats: Gain insight into your own trading behaviour by analyzing your token searches\nFind out who makes the best calls in your trading group chats",
        ]}
        reverse={false}
      />
      <DynamicSliderSection
        id="advertisement"
        title="Advertisement"
        images={[
          "/assets/screenshots/ad_terminal.png",
          "/assets/screenshots/trendingChannel.jpg",
          "/assets/screenshots/referralProgram.png",
        ]}
        descriptions={[
          "Promotions & Trending: Advertise your token, project, channel to up to 10 million users on Proficy Price Bot\nMake your token trend and stand out among the other trending tokens",
          "Promotions & Trending: Advertise your token, project, channel to up to 10 million users on Proficy Price Bot\nMake your token trend and stand out among the other trending tokens",
          "Referral Program: Earn up to 50% referral share when booking ads with Proficy\nFully automatic direct payouts",
        ]}
        reverse={true}
      />
      <PartnersSection />
      <TeamSection />
      
      <footer>
        Established 2021 - All rights Reserved - Proficy© 2025
        <a href="#" onClick={(e) => {e.preventDefault(); setShowTOSModal(true);}}>Terms of Service</a>
      </footer>
      
      {showTOSModal && <TOSModal onClose={() => setShowTOSModal(false)} />}
      {showDocModal && <DocModal onClose={() => setShowDocModal(false)} />}
    </div>
  );
}

export default App;