import React, { useEffect } from 'react';
import '../styles/TOSModal.css';

function TOSModal({ onClose }) {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    const handleClickOutside = (e) => {
      if (e.target.classList.contains('tos-modal')) {
        onClose();
      }
    };
    
    document.addEventListener('keydown', handleEsc);
    document.addEventListener('click', handleClickOutside);
    
    // Prevent scrolling of body when modal is open
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  return (
    <div className="tos-modal">
      <div className="tos-modal-content">
        <span className="tos-close-btn" onClick={onClose}>&times;</span>
        <h1>Terms of Service</h1>
        <p>Last Updated: 2/24/2025</p>
        <p>Welcome to Proficy ("Service"). Our Service provides analysis of the decentralized finance (DeFi) crypto market and tokens ("Content"). By accessing or using the Service, you agree to be bound by these Terms of Service ("Terms"). If you do not agree, please do not use our Service.</p>
        
        <h2>1. Liability Waiver</h2>
        <p>The Service is provided "as is" and "as available." Proficy makes no warranties, express or implied, regarding the accuracy, reliability, or completeness of the Content. Under no circumstances shall Proficy, its founders, employees, or affiliates be liable for any direct, indirect, incidental, consequential, or special damages arising from your use of the Service or reliance on any Content provided.</p>
        
        <h2>2. No Financial Advice</h2>
        <p>The Content provided is for informational purposes only and should not be construed as financial, investment, or legal advice. Always conduct your own research and consult with a qualified professional before making any financial decisions.</p>
        
        <h2>3. Use at Your Own Risk</h2>
        <p>You acknowledge that your use of the Service is entirely at your own risk. Proficy is not responsible for any losses or damages, including loss of profits, data, or other intangibles, that may result from your reliance on the Service.</p>
        
        <h2>4. Indemnification</h2>
        <p>You agree to indemnify and hold Proficy, its founders, employees, and affiliates harmless from any claims, liabilities, damages, or expenses arising from your use of the Service or violation of these Terms.</p>
        
        <h2>5. Modifications</h2>
        <p>Proficy reserves the right to modify these Terms at any time without prior notice. Your continued use of the Service constitutes acceptance of the modified Terms.</p>
      </div>
    </div>
  );
}

export default TOSModal;