import React, { useEffect, useState } from 'react';
import '../styles/DocModal.css';

function DocModal({ onClose }) {
  const [activeSection, setActiveSection] = useState('getStarted');
  
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    const handleClickOutside = (e) => {
      if (e.target.classList.contains('doc-modal-overlay')) {
        onClose();
      }
    };
    
    document.addEventListener('keydown', handleEsc);
    document.addEventListener('click', handleClickOutside);
    
    // Prevent scrolling when modal is open
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  const scrollToDocSection = (sectionId) => {
    setActiveSection(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      document.querySelector('.doc-content-area').scrollTop = section.offsetTop - 20;
    }
  };

  const observeDocSections = () => {
    const contentArea = document.querySelector('.doc-content-area');
    const sections = Array.from(contentArea.querySelectorAll('section'));
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        root: contentArea,
        threshold: 0.6
      }
    );
    
    sections.forEach(section => observer.observe(section));
    
    return () => observer.disconnect();
  };
  
  useEffect(() => {
    const cleanup = observeDocSections();
    return cleanup;
  }, []);

  return (
    <div className="doc-modal-overlay">
      <div className="doc-modal">
        <button className="doc-close-btn" onClick={onClose}>&times;</button>
        <div className="doc-sidebar" style={{ position: 'relative' }}>
          <h2>Documentation</h2>
          <ul id="docSidebarList">
            <li 
              data-target="getStarted" 
              onClick={() => scrollToDocSection('getStarted')}
              className={activeSection === 'getStarted' ? 'active' : ''}
            >
              Get Started
            </li>
            <li 
              data-target="promotion" 
              onClick={() => scrollToDocSection('promotion')}
              className={activeSection === 'promotion' ? 'active' : ''}
            >
              Promotion
            </li>
            <li 
              data-target="priceQueries" 
              onClick={() => scrollToDocSection('priceQueries')}
              className={activeSection === 'priceQueries' ? 'active' : ''}
            >
              Price Queries
            </li>
            <li 
              data-target="shortPriceQueries" 
              onClick={() => scrollToDocSection('shortPriceQueries')}
              className={activeSection === 'shortPriceQueries' ? 'active' : ''}
            >
              Short Price Queries
            </li>
            <li 
              data-target="chartQueries" 
              onClick={() => scrollToDocSection('chartQueries')}
              className={activeSection === 'chartQueries' ? 'active' : ''}
            >
              Chart Queries
            </li>
            <li 
              data-target="dexCommands" 
              onClick={() => scrollToDocSection('dexCommands')}
              className={activeSection === 'dexCommands' ? 'active' : ''}
            >
              DEX Commands
            </li>
            <li 
              data-target="marketSentiment" 
              onClick={() => scrollToDocSection('marketSentiment')}
              className={activeSection === 'marketSentiment' ? 'active' : ''}
            >
              Market Sentiment
            </li>
            <li 
              data-target="utilitiesBook" 
              onClick={() => scrollToDocSection('utilitiesBook')}
              className={activeSection === 'utilitiesBook' ? 'active' : ''}
            >
              Utilities
            </li>
            <li 
              data-target="pinksale" 
              onClick={() => scrollToDocSection('pinksale')}
              className={activeSection === 'pinksale' ? 'active' : ''}
            >
              Pinksale
            </li>
          </ul>
          
          {/* Watermark */}
          <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: `url("/assets/icons/logo_bg.png") no-repeat center`,
            backgroundSize: 'contain',
            width: '200px', 
            height: '100px',
            opacity: '0.2',
            pointerEvents: 'none'
          }} />
        </div>
        <div className="doc-content-area">
          <section id="getStarted">
            <h2>Get Started</h2>
            <p>
              Simply add {' '}
              <a href="https://t.me/ProficyPriceBot" target="_blank" rel="noopener noreferrer">
                <strong>@ProficyPriceBot</strong>
              </a> {' '}
              to your group chat or DM it directly to get started.
              <br/>Visit the ad-terminal to book an advertisement {' '}
              <a href="https://t.me/ProficyTrendingBot" target="_blank" rel="noopener noreferrer">
                <strong>@ProficyTrendingBot</strong>
              </a>.
            </p>
          </section>
          <section id="promotion">
            <h2>Promotion</h2>
            <ul>
              <li><strong>/promo</strong> – Promote Your Token or Channel on @ProficyPriceBot</li>
            </ul>
          </section>
          <section id="priceQueries">
            <h2>Price Queries</h2>
            <p>Use these commands to get token prices by contract address, token name, or token symbol.</p>
            <ul>
              <li><strong>/p</strong> and <strong>/r</strong> – Price by token name, symbol or contract address
                <ul>
                  <li><strong>/p cake</strong></li>
                  <li><strong>/p pancakeswap</strong></li>
                  <li><strong>/p 0xABC123…</strong></li>
                </ul>
              </li>
            </ul>
          </section>
          <section id="shortPriceQueries">
            <h2>Short Price Queries</h2>
            <p>Retrieve a brief price description along with historical info.</p>
            <ul>
              <li><strong>/s</strong> and <strong>/d</strong> – Short price info
                <ul>
                  <li><strong>/s doge</strong></li>
                  <li><strong>/s 0xDEF456…</strong></li>
                </ul>
              </li>
            </ul>
          </section>
          <section id="chartQueries">
            <h2>Chart Queries</h2>
            <p>Display token charts. Append an "i" for quick price info. Interval commands work similarly.</p>
            <ul>
              <li><strong>/c</strong> and <strong>/ci</strong> – Chart by token name, symbol or contract address
                <ul>
                  <li><strong>/c cake</strong></li>
                  <li><strong>/c 0xGHI789…</strong></li>
                </ul>
              </li>
              <li>
                <strong>Time Intervals</strong>:<br/>
                <strong>/1s, /1m, /3m, /5m, /15m, /30m, /1h, /2h, /4h, /8h, /12h, /1d, /3d, /1w</strong><br/>
                (Add an "i" for detailed info, e.g. <strong>/1hi</strong> or <strong>/ci eth</strong>)<br/>
              </li>
            </ul>
          </section>
          <section id="dexCommands">
            <h2>DEX Commands</h2>
            <ul>
              <li><strong>/trending</strong> – Proficy Trending Token Searches</li>
              <li><strong>/volume</strong> – Top DEX Volume</li>
              <li><strong>/pairs</strong> – Scan for new liquidity pairs</li>
              <li><strong>/dext</strong> and <strong>/ave</strong> – Find Hot Trending Tokens on DexTools and Ave</li>
            </ul>
          </section>
          <section id="marketSentiment">
            <h2>Market Sentiment</h2>
            <ul>
              <li><strong>/index</strong> – Top 10 Coins by Market Cap</li>
              <li><strong>/fed</strong> – FedWatch target rates for the next FOMC meeting</li>
              <li><strong>/fearindex</strong> – BTC Fear and Greed Index</li>
              <li><strong>/futures</strong> – Binance Futures Long/Short Ratio</li>
            </ul>
          </section>
          <section id="utilitiesBook">
            <h2>Utilities</h2>
            <ul>
              <li><strong>/flexstats</strong> – Check your group's or your own caller performance</li>
              <li><strong>/qa</strong> – Get a ChatGPT response by replying to a message</li>
              <li><strong>/translate</strong> – Translate text or replies to English</li>
              <li><strong>/translatecn</strong> – Translate text or replies to Chinese</li>
            </ul>
          </section>
          <section id="pinksale">
            <h2>Pinksale</h2>
            <ul>
              <li><strong>/presales</strong>, <strong>/pinksale</strong>, and <strong>/active</strong> – 10 currently running PinkSale presales</li>
              <li><strong>/next</strong> – Next 10 upcoming PinkSale presales</li>
              <li><strong>/active</strong> – Display active Pinksale presales</li>
              <li><strong>/pink</strong> – Search any PinkSale token</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

export default DocModal;